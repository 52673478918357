// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EOmdQ{margin-bottom:12px;padding:24px;background-color:var(--wpp-primary-color-100);border-radius:var(--wpp-border-radius-m);box-shadow:var(--wpp-box-shadow-s)}.AgOXl{flex-shrink:0}", "",{"version":3,"sources":["webpack://./src/components/conversation/questionBubble/QuestionBubble.module.scss"],"names":[],"mappings":"AAAA,OACE,kBAAA,CACA,YAAA,CACA,6CAAA,CACA,wCAAA,CACA,kCAAA,CAGF,OACE,aAAA","sourcesContent":[".questionBubble {\n  margin-bottom: 12px;\n  padding: 24px;\n  background-color: var(--wpp-primary-color-100);\n  border-radius: var(--wpp-border-radius-m);\n  box-shadow: var(--wpp-box-shadow-s);\n}\n\n.avatar {\n  flex-shrink: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"questionBubble": "EOmdQ",
	"avatar": "AgOXl"
};
export default ___CSS_LOADER_EXPORT___;
