import { narrativesComposerApi } from 'api'
import { PaginatedResponse, PaginationParams } from 'api/common/types'
import { ConversationDto } from 'types/dto/ConversationDto'

export interface SavedConversationsParams {
  userId: string
}

export const fetchSavedConversations = ({ page, itemsPerPage, userId }: PaginationParams<SavedConversationsParams>) =>
  narrativesComposerApi.get<PaginatedResponse<ConversationDto>>('/saved', { params: { page, itemsPerPage, userId } })
