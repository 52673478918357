// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dX7bz{margin-bottom:12px;padding:24px;background-color:var(--wpp-grey-color-000);border-radius:var(--wpp-border-radius-m);box-shadow:var(--wpp-box-shadow-s)}.wtj9C{width:100%;margin-top:20px}.fLw3a{margin-bottom:8px;margin-right:8px}", "",{"version":3,"sources":["webpack://./src/components/conversation/answerBubble/AnswerBubble.module.scss"],"names":[],"mappings":"AAAA,OACE,kBAAA,CACA,YAAA,CACA,0CAAA,CACA,wCAAA,CACA,kCAAA,CAGF,OACE,UAAA,CACA,eAAA,CAGF,OACE,iBAAA,CACA,gBAAA","sourcesContent":[".answerBubble {\n  margin-bottom: 12px;\n  padding: 24px;\n  background-color: var(--wpp-grey-color-000);\n  border-radius: var(--wpp-border-radius-m);\n  box-shadow: var(--wpp-box-shadow-s);\n}\n\n.articlesContainer {\n  width: 100%;\n  margin-top: 20px;\n}\n\n.keywordPill {\n  margin-bottom: 8px;\n  margin-right: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"answerBubble": "dX7bz",
	"articlesContainer": "wtj9C",
	"keywordPill": "fLw3a"
};
export default ___CSS_LOADER_EXPORT___;
