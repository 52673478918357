import { WppTypography } from '@platform-ui-kit/components-library-react'
import Lottie from 'lottie-react'

import loadingAI from 'assets/animations/loadingAI.json'
import { Flex } from 'components/common/flex/Flex'

import styles from './AiLoadingAnimation.module.scss'

export const AiLoadingAnimation = () => {
  return (
    <Flex direction="column" justify="center" align="center" className={styles.animationContainer}>
      <Lottie animationData={loadingAI} className={styles.animation} />
      <WppTypography tag="h3" type="l-strong" className={styles.title}>
        We are working on generating personalized content
      </WppTypography>
      <WppTypography tag="p" type="s-body">
        Please be patient while we analyze your brief and create something tailored just for you.
      </WppTypography>
    </Flex>
  )
}
