import { WppTypography } from '@platform-ui-kit/components-library-react'

import { DocumentDto } from 'types/dto/DocumentDto'

import styles from './ArticlesListCardItem.module.scss'

interface Props {
  document: DocumentDto
}

export const ArticlesListCardItem = ({ document }: Props) => {
  return (
    <a href={`${document.filePath}${document.token}`} target="_blank" rel="noreferrer" className={styles.articleCard}>
      <img alt="" src={`${document.thumbnailPath}${document.token}`} />
      <div className={styles.cardContent}>
        <div>
          {/* TODO get this value from API */}
          <WppTypography tag="p" type="2xs-strong">
            Article
          </WppTypography>
        </div>
        <div>
          <WppTypography tag="p" type="m-strong" className={styles.articleTitle}>
            {document.title}
          </WppTypography>
        </div>
        {document.tags.map((tag: string, index: number) => (
          <span key={index}>{tag}</span>
        ))}
      </div>
    </a>
  )
}
