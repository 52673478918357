import { createUseQuery } from 'api/common/createUseQuery'
import { ApiQueryKeys } from 'constants/apiQueryKeys'

import { fetchDocuments } from '../fetchers/fetchDocuments'

export const useFetchDocuments = createUseQuery({
  queryKey: ApiQueryKeys.DOCUMENTS,
  fetcher: fetchDocuments,
  selector: res => res?.data ?? [],
})
