import { PillGroupChangeEvent } from '@platform-ui-kit/components-library'
import { WppPillGroupCustomEvent } from '@platform-ui-kit/components-library/loader'
import { WppTypography, WppIconBot, WppPill, WppPillGroup } from '@platform-ui-kit/components-library-react'
import { useState, SetStateAction, useEffect } from 'react'

import { Flex } from 'components/common/flex/Flex'

import styles from './AnswerBubble.module.scss'
import { ArticlesList } from './articlesList/ArticlesList'
import { ConversationDto } from '../../../types/dto/ConversationDto'

interface Props {
  answer: ConversationDto
}

export const AnswerBubble = ({ answer }: Props) => {
  const [selectedKeyword, setSelectedKeyword] = useState<string | undefined>(undefined)

  const handleKeywordSelect = (event: WppPillGroupCustomEvent<PillGroupChangeEvent>) => {
    setSelectedKeyword(event.detail.value as SetStateAction<string | undefined>)
  }

  useEffect(() => {
    setSelectedKeyword(undefined)
  }, [answer])

  return (
    <>
      <div className={styles.answerBubble}>
        <Flex direction="row" wrap="nowrap" gap={12}>
          <WppIconBot height={32} width={32} color="var(--wpp-grey-color-800)" />
          <WppTypography tag="p" type="s-body">
            {answer.response}
          </WppTypography>
        </Flex>
      </div>
      {answer.keywordsList.length > 0 && (
        <div className={styles.answerBubble}>
          <WppTypography type="xl-heading" tag="h3">
            Keywords
          </WppTypography>
          <div className={styles.articlesContainer}>
            {selectedKeyword ? (
              <WppPillGroup type="single" value={selectedKeyword} onWppChange={handleKeywordSelect}>
                <div>
                  {answer.keywordsList.map((keyword, index) => (
                    <WppPill label={keyword} value={keyword} key={index} className={styles.keywordPill} />
                  ))}
                </div>
              </WppPillGroup>
            ) : (
              <div>
                {answer.keywordsList.map((keyword, index) => (
                  <span key={index} onClick={() => setSelectedKeyword(keyword)}>
                    <WppPill label={keyword} value={keyword} className={styles.keywordPill} />
                  </span>
                ))}
              </div>
            )}

            {selectedKeyword && <ArticlesList keyword={selectedKeyword} />}
          </div>
        </div>
      )}
    </>
  )
}
