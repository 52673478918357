import clsx from 'clsx'
import { ElementType, forwardRef, ComponentPropsWithoutRef, ComponentRef, ComponentProps, Ref } from 'react'

import styles from 'components/common/flex/Flex.module.scss'

interface OwnProps {
  inline?: boolean
  gap?: number
  justify?: 'start' | 'center' | 'end' | 'between' | 'around'
  align?: 'start' | 'center' | 'end'
  direction?: 'row' | 'column'
  wrap?: 'nowrap' | 'wrap'
}

interface FlexImplementation {
  <T extends ElementType>(
    props: ComponentPropsWithoutRef<T> & { as: T; ref?: Ref<ComponentRef<T>> } & OwnProps,
  ): JSX.Element
  (props: ComponentProps<'div'> & { as?: never } & OwnProps): JSX.Element
}

export const Flex = forwardRef(function Flex(props: Omit<Parameters<FlexImplementation>[0], 'ref'>, ref) {
  const {
    as: As = 'div',
    inline = false,
    direction = 'row',
    wrap,
    align,
    justify,
    gap,
    className,
    style,
    ...rest
  } = props

  return (
    <As
      ref={ref}
      {...rest}
      style={{ ...style, ...(!!gap && { gap: `${gap}px` }) }}
      className={clsx(
        styles.root,
        {
          [styles.inline]: inline,
          [styles[`${wrap}`]]: !!wrap,
          [styles.column]: direction === 'column',
          [styles[`align-${align}`]]: !!align,
          [styles[`justify-${justify}`]]: !!justify,
        },
        className,
      )}
    />
  )
}) as FlexImplementation
