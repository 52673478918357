import { narrativesComposerApi } from 'api'
import { CancelableRequestProducer } from 'api/common/types'
import { ConversationDto } from 'types/dto/ConversationDto'

export interface PromptParams {
  userId: string
  prompt: string
  saved: boolean
}

export const createPrompt = ({ userId, prompt, saved }: PromptParams): CancelableRequestProducer<ConversationDto> =>
  narrativesComposerApi.post('/prompt', { userId, prompt, saved })
