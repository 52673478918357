// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MqRSy{display:block;overflow:hidden;background-color:var(--wpp-grey-color-100);border-radius:var(--wpp-border-radius-m);box-shadow:var(--wpp-box-shadow-s)}.MqRSy img{width:100%;transition:transform .5s ease;aspect-ratio:219/142}.MqRSy:hover{box-shadow:var(--wpp-box-shadow-m)}.MqRSy:hover img{transform:scale(1.05)}.akScj{padding:12px 12px 20px}.S7DfB{padding-top:6px;padding-bottom:12px}", "",{"version":3,"sources":["webpack://./src/components/conversation/answerBubble/articlesList/articlesListCardItem/ArticlesListCardItem.module.scss"],"names":[],"mappings":"AAAA,OACE,aAAA,CACA,eAAA,CACA,0CAAA,CACA,wCAAA,CACA,kCAAA,CAEA,WACE,UAAA,CACA,6BAAA,CACA,oBAAA,CAGF,aACE,kCAAA,CAEA,iBACE,qBAAA,CAKN,OACE,sBAAA,CAGF,OACE,eAAA,CACA,mBAAA","sourcesContent":[".articleCard {\n  display: block;\n  overflow: hidden;\n  background-color: var(--wpp-grey-color-100);\n  border-radius: var(--wpp-border-radius-m);\n  box-shadow: var(--wpp-box-shadow-s);\n\n  & img {\n    width: 100%;\n    transition: transform 0.5s ease;\n    aspect-ratio: 219 / 142;\n  }\n\n  &:hover {\n    box-shadow: var(--wpp-box-shadow-m);\n\n    & img {\n      transform: scale(1.05);\n    }\n  }\n}\n\n.cardContent {\n  padding: 12px 12px 20px;\n}\n\n.articleTitle {\n  padding-top: 6px;\n  padding-bottom: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"articleCard": "MqRSy",
	"cardContent": "akScj",
	"articleTitle": "S7DfB"
};
export default ___CSS_LOADER_EXPORT___;
