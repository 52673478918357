import { useOs } from '@wpp-open/react'
import { useState } from 'react'
import { useToggle } from 'react-use'

import { useFetchSavedConversations } from 'api/narrativesComposer/queries/useFetchSavedConversations'
import { AiLoadingAnimation } from 'components/aiLoadingAnimation/AiLoadingAnimation'
import { Conversation } from 'components/conversation/Conversation'

import styles from './NarrativesComposer.module.scss'
import { Section, sections } from './questions.data'
import { SavedConversation } from '../../components/savedConversations/SavedConversations'
import { Search } from '../../components/search/Search'
import { ConversationDto } from '../../types/dto/ConversationDto'

export const NarrativesComposer = () => {
  const { osContext } = useOs()
  const { id: userId } = osContext.userDetails

  const { data: savedConversations } = useFetchSavedConversations({
    params: { itemsPerPage: 1000, userId: userId }, // TODO implement pagination if needed
  })

  const [isChecked, setIsChecked] = useToggle(true)
  const [selectedSection, setSelectedSection] = useState<Section>(sections[0])
  const [selectedConversation, setSelectedConversation] = useState<ConversationDto | null>(null)
  const [isLoadingAnswer, setIsLoadingAnswer] = useState(false)

  const handleConversationChange = (conversation: ConversationDto | null) => {
    setSelectedConversation(conversation)
  }

  if (isLoadingAnswer) return <AiLoadingAnimation />

  return (
    <>
      <SavedConversation
        savedConversations={savedConversations}
        isChecked={isChecked}
        setIsChecked={setIsChecked}
        selectedConversation={selectedConversation}
        handleConversationChange={handleConversationChange}
      />
      <div className={styles.mainContentContainer}>
        {selectedConversation ? (
          <Conversation conversation={selectedConversation} />
        ) : (
          <Search
            selectedSection={selectedSection}
            setSelectedSection={setSelectedSection}
            isChecked={isChecked}
            handleConversationChange={handleConversationChange}
            handleLoadingAnswerChange={setIsLoadingAnswer}
          />
        )}
      </div>
    </>
  )
}
