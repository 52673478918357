export interface Section {
  title: string
  questions: string[]
}

export const sections = [
  {
    title: 'Current Treatments',
    questions: [
      'What is the main unmet need in the aRCC treatment space?',
      'What is the current SoC in the various Ex-US markets, and why is it the leader (e.g. efficacy, safety, access)?',
      'What are the unique benefits of the current Rx options by medication for aRCC?',
      'What are the current challenges facing HCPs and patients in treating aRCC?',
      'What role do physician extenders (NPs/PAs) play in prescribing Inlyta?',
      'What factors drive the prevalence of aRCC?',
      'How many aRCC patients are there in the US and across key ex-US markets?',
    ],
  },
  {
    title: 'Social Listening',
    questions: [
      'What are HCPs saying about INLYTA and the competitive set in social spaces?',
      'What are patients saying about their experience with aRCC?',
      'Provide any known firsthand experience from HCPs talking about treating aRCC patients, and patients talking about living with aRCC? Include actual quotes.',
    ],
  },
  {
    title: 'Future Pipeline',
    questions: [
      'What does the pipeline look like for treatment options in the aRCC space across key Ex-US markets?',
      'What drugs currently in clinical trials have potential to disrupt the treatment landscape?',
    ],
  },
  {
    title: 'Patients',
    questions: [
      'What is the demographic patient profile and geographic prevalence of aRCC in the US and across ex-US markets?',
      'What is the current patient journey, starting from pre-diagnosis through to the various phases of treatment?',
      'How does the journey look different in an early stage vs. late stage patient?',
      'How is a patient diagnosed?',
      'What risk factors or comorbidities exist in aRCC?',
      'What are the most pertinent needs of aRCC patients?',
    ],
  },
  {
    title: 'Brand Positioning',
    questions: [
      'What makes INLYTA differentiated from the current competitive set? How does the efficacy and tolerability compare to other Rx options?',
    ],
  },
]
