// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PBfUt{display:grid;grid-gap:24px 24.5px;grid-template-rows:repeat(2, 1fr);grid-template-columns:repeat(4, 1fr);width:100%;margin-top:20px}", "",{"version":3,"sources":["webpack://./src/components/conversation/answerBubble/articlesList/ArticlesList.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CACA,oBAAA,CACA,iCAAA,CACA,oCAAA,CACA,UAAA,CACA,eAAA","sourcesContent":[".articlesList {\n  display: grid;\n  grid-gap: 24px 24.5px;\n  grid-template-rows: repeat(2, 1fr);\n  grid-template-columns: repeat(4, 1fr);\n  width: 100%;\n  margin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"articlesList": "PBfUt"
};
export default ___CSS_LOADER_EXPORT___;
