import { WppSkeleton } from '@platform-ui-kit/components-library-react'

import { useFetchDocuments } from 'api/narrativesComposer/queries/useFetchDocuments'

import styles from './ArticlesList.module.scss'
import { ArticlesListCardItem } from './articlesListCardItem/ArticlesListCardItem'

interface Props {
  keyword: string
}

export const ArticlesList = ({ keyword }: Props) => {
  const { data: documents, isLoading: isLoadingDocuments } = useFetchDocuments({
    params: { documentsPerPage: 1000, query: keyword }, // TODO implement pagination if needed
  })

  if (isLoadingDocuments)
    return (
      <div className={styles.articlesList}>
        {[...Array(16)].map((e, i) => (
          <WppSkeleton key={i} variant="rectangle" width="100%" height="287" />
        ))}
      </div>
    )

  return (
    <div className={styles.articlesList}>
      {documents &&
        documents.map((document: any, index: number) => <ArticlesListCardItem document={document} key={index} />)}
    </div>
  )
}
