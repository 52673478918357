// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JjK6k{--app-top-padding: 28px;--app-horizontal-padding: 38px;--app-bottom-padding: 8px;--app-sidebar-width: 262px;--app-answer-max-width: 1440px;min-height:calc(100vh - var(--wpp-os-header-height));padding:var(--app-top-padding) var(--app-horizontal-padding) var(--app-bottom-padding);background-color:var(--wpp-grey-color-100)}", "",{"version":3,"sources":["webpack://./src/app/App.module.scss"],"names":[],"mappings":"AAAA,OACE,uBAAA,CACA,8BAAA,CACA,yBAAA,CAEA,0BAAA,CACA,8BAAA,CAEA,oDAAA,CACA,sFAAA,CACA,0CAAA","sourcesContent":[".container {\n  --app-top-padding: 28px;\n  --app-horizontal-padding: 38px;\n  --app-bottom-padding: 8px;\n\n  --app-sidebar-width: 262px;\n  --app-answer-max-width: 1440px;\n\n  min-height: calc(100vh - var(--wpp-os-header-height));\n  padding: var(--app-top-padding) var(--app-horizontal-padding) var(--app-bottom-padding);\n  background-color: var(--wpp-grey-color-100);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "JjK6k"
};
export default ___CSS_LOADER_EXPORT___;
