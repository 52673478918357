import { narrativesComposerApi } from 'api'
import { DocumentDto } from 'types/dto/DocumentDto'

export interface DocumentsParams {
  query: string
  documentsPerPage: number
}

export const fetchDocuments = ({ query, documentsPerPage }: DocumentsParams) =>
  narrativesComposerApi.get<DocumentDto[]>('/documents', { params: { query, documentsPerPage } })
