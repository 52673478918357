import {
  WppIconSend,
  WppSegmentedControl,
  WppSegmentedControlItem,
  WppTextareaInput,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { useOs } from '@wpp-open/react'
import { useState, FormEvent, KeyboardEvent } from 'react'

import { useCreatePrompt } from 'api/narrativesComposer/mutations/useCreatePrompt'

import styles from './Search.module.scss'
import { Section, sections } from '../../pages/narrativesComposer/questions.data'
import { ConversationDto } from '../../types/dto/ConversationDto'
import { Flex } from '../common/flex/Flex'

interface Props {
  selectedSection: Section
  setSelectedSection: (value: Section) => void
  isChecked: boolean
  handleConversationChange: (conversation: ConversationDto | null) => void
  handleLoadingAnswerChange: (isLoading: boolean) => void
}

export const Search = ({
  setSelectedSection,
  selectedSection,
  isChecked,
  handleConversationChange,
  handleLoadingAnswerChange,
}: Props) => {
  const { osContext } = useOs()
  const { id: userId } = osContext.userDetails
  const { mutateAsync: createPrompt } = useCreatePrompt()

  const [inputValue, setInputValue] = useState('')

  const onPromptKeyDown = (event: KeyboardEvent<HTMLWppTextareaInputElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      handleAskPrompt()
    }
  }

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    event.stopPropagation()
    handleAskPrompt()
  }

  const handleAskPrompt = async () => {
    if (!inputValue.trim() || inputValue.length > 2000) return

    handleLoadingAnswerChange(true)
    try {
      const { data } = await createPrompt({ userId: userId, prompt: inputValue, saved: isChecked })
      if (data) handleConversationChange(data)
    } catch (err) {
      console.error(err)
    } finally {
      handleLoadingAnswerChange(false)
    }
  }

  return (
    <Flex className={styles.recentSearch} justify="between" direction="column">
      <div>
        <WppTypography type="xl-heading" tag="h4" className={styles.title}>
          Recent search
        </WppTypography>
        <WppSegmentedControl value={selectedSection.title} size="s" hugContentOff>
          {sections.map(section => (
            <WppSegmentedControlItem
              value={section.title}
              key={section.title}
              onClick={() => setSelectedSection(section)}
            >
              {section.title}
            </WppSegmentedControlItem>
          ))}
        </WppSegmentedControl>
        <Flex direction="column" gap={12} className={styles.questions}>
          {selectedSection.questions.map(question => (
            <div key={question} className={styles.question} onClick={() => setInputValue(question)}>
              <WppTypography type="s-body" tag="span">
                {question}
              </WppTypography>
            </div>
          ))}
        </Flex>
      </div>

      <form className={styles.textInput} onSubmit={onSubmit}>
        <WppTextareaInput
          name="query"
          placeholder="Send a question ..."
          value={inputValue}
          rows={5}
          charactersLimit={2000}
          message={inputValue.length > 2000 ? 'You can ask up to 2000 characters' : ''}
          messageType={inputValue.length > 2000 ? 'error' : undefined}
          warningThreshold={2000}
          onWppChange={e => setInputValue(e.target.value)}
          onKeyDown={onPromptKeyDown}
        />
        <button type="submit" className={styles.submitPromptIconButton}>
          <WppIconSend slot="icon-end" aria-label="Send icon" />
        </button>
      </form>
    </Flex>
  )
}
