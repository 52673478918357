// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".so61M{width:100%;height:calc(100vh - var(--wpp-os-header-height) - 60px)}.R1bCY{display:block;padding-bottom:24px;text-align:center}.DO0W5{width:100%;padding:12px;background-color:var(--wpp-primary-color-100);border-radius:8px;cursor:pointer}.JnR6d{padding:24px 0}.p2aNa{position:relative}.zHfcw{position:absolute;right:12px;bottom:32px;width:20px;height:20px;cursor:pointer}", "",{"version":3,"sources":["webpack://./src/components/search/Search.module.scss"],"names":[],"mappings":"AAAA,OACE,UAAA,CACA,uDAAA,CAGF,OACE,aAAA,CACA,mBAAA,CACA,iBAAA,CAGF,OACE,UAAA,CACA,YAAA,CACA,6CAAA,CACA,iBAAA,CACA,cAAA,CAGF,OACE,cAAA,CAGF,OACE,iBAAA,CAGF,OACE,iBAAA,CACA,UAAA,CACA,WAAA,CACA,UAAA,CACA,WAAA,CACA,cAAA","sourcesContent":[".recentSearch {\n  width: 100%;\n  height: calc(100vh - var(--wpp-os-header-height) - 60px);\n}\n\n.title {\n  display: block;\n  padding-bottom: 24px;\n  text-align: center;\n}\n\n.question {\n  width: 100%;\n  padding: 12px;\n  background-color: var(--wpp-primary-color-100);\n  border-radius: 8px;\n  cursor: pointer;\n}\n\n.questions {\n  padding: 24px 0;\n}\n\n.textInput {\n  position: relative;\n}\n\n.submitPromptIconButton {\n  position: absolute;\n  right: 12px;\n  bottom: 32px;\n  width: 20px;\n  height: 20px;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"recentSearch": "so61M",
	"title": "R1bCY",
	"question": "DO0W5",
	"questions": "JnR6d",
	"textInput": "p2aNa",
	"submitPromptIconButton": "zHfcw"
};
export default ___CSS_LOADER_EXPORT___;
