// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pKzvl{position:relative;left:var(--app-sidebar-width);width:calc(100% - var(--app-sidebar-width));max-width:var(--app-answer-max-width);padding-left:28px}", "",{"version":3,"sources":["webpack://./src/pages/narrativesComposer/NarrativesComposer.module.scss"],"names":[],"mappings":"AAAA,OACE,iBAAA,CACA,6BAAA,CACA,2CAAA,CACA,qCAAA,CACA,iBAAA","sourcesContent":[".mainContentContainer {\n  position: relative;\n  left: var(--app-sidebar-width);\n  width: calc(100% - var(--app-sidebar-width));\n  max-width: var(--app-answer-max-width);\n  padding-left: 28px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainContentContainer": "pKzvl"
};
export default ___CSS_LOADER_EXPORT___;
