// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EV7RJ{width:100%;height:calc(100vh - var(--wpp-os-header-height) - var(--app-top-padding) - var(--app-bottom-padding))}.E0I5t{display:flex;justify-content:center;margin-top:-200px;margin-bottom:-100px}.E0I5t svg{max-width:800px}.LwkxO{padding-bottom:21px}", "",{"version":3,"sources":["webpack://./src/components/aiLoadingAnimation/AiLoadingAnimation.module.scss"],"names":[],"mappings":"AAAA,OACE,UAAA,CACA,qGAAA,CAGF,OACE,YAAA,CACA,sBAAA,CACA,iBAAA,CACA,oBAAA,CAEA,WACE,eAAA,CAIJ,OACE,mBAAA","sourcesContent":[".animationContainer {\n  width: 100%;\n  height: calc(100vh - var(--wpp-os-header-height) - var(--app-top-padding) - var(--app-bottom-padding));\n}\n\n.animation {\n  display: flex;\n  justify-content: center;\n  margin-top: -200px;\n  margin-bottom: -100px;\n\n  & svg {\n    max-width: 800px;\n  }\n}\n\n.title {\n  padding-bottom: 21px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"animationContainer": "EV7RJ",
	"animation": "E0I5t",
	"title": "LwkxO"
};
export default ___CSS_LOADER_EXPORT___;
