import styles from 'app/App.module.scss'
import { NarrativesComposer } from 'pages/narrativesComposer/NarrativesComposer'

export function App() {
  /**
   * This hook is provided by the utility package for more convenient work with OS context
   * @return osContext
   * - is a subscribe function that returns you the main context object with MicroAppContext data
   * @return osApi
   * - methods to fire actions on the parent OS level
   *
   * Read more about it in the README file
   */

  return (
    <div className={styles.container}>
      <NarrativesComposer />
    </div>
  )
}
