import { WppButton, WppDivider, WppToggle, WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'

import styles from './SavedConversations.module.scss'
import { ConversationDto } from '../../types/dto/ConversationDto'
import { Flex } from '../common/flex/Flex'

interface SavedConversationPrompts {
  isChecked: boolean
  setIsChecked: (value: boolean) => void
  savedConversations: ConversationDto[]
  selectedConversation: ConversationDto | null
  handleConversationChange: (conversation: ConversationDto | null) => void
}

export const SavedConversation = ({
  isChecked,
  setIsChecked,
  savedConversations,
  selectedConversation,
  handleConversationChange,
}: SavedConversationPrompts) => {
  return (
    <Flex className={styles.savedConversations} direction="column" justify="between">
      <div className={styles.savedConversationsList}>
        {savedConversations.length > 0 && (
          <>
            <WppTypography type="xl-heading" tag="h4" className={styles.title}>
              Saved conversations
            </WppTypography>
            {savedConversations.map((conversation, index) => (
              <button
                key={index}
                className={clsx(
                  styles.conversationItemButton,
                  selectedConversation?.id === conversation.id && styles.conversationItemButtonSelected,
                )}
                onClick={() => handleConversationChange(conversation)}
              >
                <WppTypography tag="span" type="s-body" className={styles.conversationItemText}>
                  {conversation.query.trim()}
                </WppTypography>
              </button>
            ))}
          </>
        )}
      </div>
      <div>
        <WppDivider />
        <Flex gap={8} className={styles.toggle}>
          <WppToggle
            value="saved"
            checked={isChecked}
            onWppChange={({ detail: { checked } }) => setIsChecked(checked)}
          />
          <WppTypography type="s-body" tag="span" className={isChecked ? undefined : styles.disabled}>
            Save current conversation
          </WppTypography>
        </Flex>
        <WppButton variant="secondary" className={styles.button} onClick={() => handleConversationChange(null)}>
          New Chat
        </WppButton>
      </div>
    </Flex>
  )
}
