import { ConversationDto } from 'types/dto/ConversationDto'

import { AnswerBubble } from './answerBubble/AnswerBubble'
import styles from './Conversation.module.scss'
import { QuestionBubble } from './questionBubble/QuestionBubble'

interface Props {
  conversation: ConversationDto
}

export const Conversation = ({ conversation }: Props) => {
  return (
    <div className={styles.chat}>
      <QuestionBubble text={conversation.query} />
      <AnswerBubble answer={conversation} />
    </div>
  )
}
