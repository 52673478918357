import { createUseQuery } from 'api/common/createUseQuery'
import { ApiQueryKeys } from 'constants/apiQueryKeys'

import { fetchSavedConversations } from '../fetchers/fetchSavedConversations'

export const useFetchSavedConversations = createUseQuery({
  queryKey: ApiQueryKeys.SAVED_CONVERSATIONS,
  fetcher: fetchSavedConversations,
  selector: res => res?.data.data ?? [],
})
