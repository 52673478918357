import { WppTypography, WppAvatar } from '@platform-ui-kit/components-library-react'

import { Flex } from 'components/common/flex/Flex'

import styles from './QuestionBubble.module.scss'

interface Props {
  text: string
  avatarUrl?: string
}

export const QuestionBubble = ({ text, avatarUrl }: Props) => {
  return (
    <Flex className={styles.questionBubble} direction="row" wrap="nowrap" gap={12}>
      <WppAvatar size="s" src={avatarUrl ?? ''} className={styles.avatar} />
      <WppTypography tag="p" type="s-body">
        {text}
      </WppTypography>
    </Flex>
  )
}
